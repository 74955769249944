/*
* 업무구분: 리크루팅
* 화면 명:  핸드폰 인증
* 화면 설명:  핸드폰 인증
* 화면 접근권한:
*/
<template>
  <ur-page-container :show-title="true" type="subpage" title="핸드폰인증">
    <ur-box-container direction="column" alignV="start">
      <mo-validate-watcher ref="vWatcher">
        <ur-box-container direction="column" alignV="start" componentid="ur_box_container_000" class="ns-verify">
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="verify-box pb20">
            <mo-text-field ref="custNmRef" v-model="custCardVO.custNm" :rules="validateRuleRequire" underline placeholder="고객명을 입력해 주세요"
             maxlength="30" class="form-input-name" @blur="fn_NextComp('knbFrnoRef')"/>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column">
            <div class="verify-birth">
              <mo-text-field ref="knbFrnoRef" type="number" mask="######" :rules="validateRuleKnbFrno" v-model="custCardVO.knbFrno" 
                underline class="birth-input1 form-input-name" placeholder="생년월일"/>
              <span>-</span>
              <mo-text-field ref="knbBknoRef" type="number" mask="#" v-model="custCardVO.knbBkno" :rules="validateRuleRequire" 
                underline class="birth-input2 form-input-name" placeholder="0"/>
              <em>******</em>
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column">
            <div class="verify-call">
              <msp-bottom-select ref="telecomTypRef" :items="telecomTypes" v-model="fn_TelecomType" underline class="ns-dropdown-sheet call1" placeholder="통신사" bottom-title="통신사를 선택해 주세요" closeBtn/>
              <msp-bottom-select :items="phoneItems" v-model="custCardVO.celnoFrno" underline class="ns-dropdown-sheet call2" />
              <mo-text-field ref="celnoRef" v-model="custCardVO.celnoBkno" type="number" mask="########" placeholder="0000000"  :rules="validateRuleCelno" underline class="phone-number1 form-input-name"/>
            </div>
            <div class="ns-check arrow">
              <mo-checkbox v-model="agreeCheckbox" @input="fn_AgreeCnfrmPop2('Open')">본인확인 서비스 이용 동의</mo-checkbox>
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="verify-btn2">
            <mo-button class="ns-btn-round" @click="fn_BtnP1OnClick">{{btnNm}}</mo-button> <!-- 인증번호 전송 -->
            <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="verify-input">
              <mo-text-field ref="error" type="number" v-model="authNumber" underline class="birth-input2 form-input-name" :disabled="authNumberDisabled" placeholder="인증번호를 입력해주세요"/>
              <span v-if="isCountStart">인증번호 만료까지 {{ minutes | twoDigits }}:{{ seconds | twoDigits }}</span>
            </ur-box-container>
            <mo-button class="ns-btn-round" :disabled="authNumberDisabled" @click="fn_PrcsMoblAthntCnf">인증확인</mo-button>
            <mo-button class="ns-btn-round" v-if="isDev" @click="fn_AuthCompleted">인증통과</mo-button>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check">
              <mo-checkbox v-if="isAuthCompleted" class="is-checked" readonly>인증되었습니다.</mo-checkbox>
              <span v-if="isAuthError">{{ authErrorMsg }}</span>
            </ur-box-container>

            <span class="bottom-txt">
              인증번호 미 도착 시 아래와 같이 조치 부탁드립니다.<br>
              휴대폰 스팸번호 분류확인<br>
              NICE인증 고객센터 문의 <span class="txtBlue">&#9742;1600-1522</span>
            </span>
          </ur-box-container>
          <!-- 이용동의 안내-->
          <!-- 
          <mo-modal ref="modal" title="Title" no-header>
            <template>
              <span>{{ agreeMsg }}</span>          
            </template>
            <template slot="action">
              <mo-button @click="fn_OnNegative()">취소</mo-button>
              <mo-button @click="fn_AgreeConfirmYes()" main>확인</mo-button>
            </template>
          </mo-modal>
          -->
          <!-- 이용동의 안내 bottomSheet -->
          <div class="ns-bottom-sheet msp">
            <mo-bottom-sheet ref="agreeCnfrmPop2" :close-btn="false" class="ns-bottom-sheet closebtn" @before-close="fn_SheetClose">
              <template v-slot:title>
                본인확인서비스 이용동의
                <div class="ns-btn-close" @click="fn_AgreeCnfrmPop2('CloseBtn')" name="닫기"></div>
              </template>
              <div class="content-area">
                <ul class="terms-list-area pb36">
                  <li>개인정보 수집/이용 동의</li>
                  <li>고유식별정보 처리 동의</li>
                  <li>통신사 이용약관 동의</li>
                  <li>서비스 이용약관 동의</li>
                  <li>개인정보 제 3자 제공 동의</li>
                </ul>
              </div>

              <template v-slot:action>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_005" color="primary" shape="primary" size="border" class="ns-btn-round white" @click="fn_AgreeCnfrmPop2('Confirm')">약관 확인</mo-button>
                    <mo-button componentid="mo_button_006" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AgreeCnfrmPop2('AllConfirm')">전체 약관 동의</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>
          </div>

          <mo-bottom-sheet ref="modalFrm" class="ns-bottom-sheet ns-style3">
            <div>
            <p class="cancel-ment" v-html="agreeMsg">
            </p>
            </div>
            <template v-slot:action>
              <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_OnNegative()">취소</mo-button>
                  <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AgreeConfirmYes()">확인</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>
        </ur-box-container>
      </mo-validate-watcher>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPCM010P from '@/ui/cm/MSPCM010P' // 알뜰폰 안내
  import moment from 'moment'
  import Screen from '~systems/mixin/screen'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPRC120M",
    screenId: "MSPRC120M",
    mixins: [Screen],
    components: {
      MspBottomSelect,
      MSPCM010P
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {      
      let lv_Vm = this
      this.agreeMsg = `본인확인 서비스 이용에 동의하셔야 인증번호가 전송됩니다. <br/> 이용에 동의하시겠습니까?`
      // ---------------------------------------------------------------------------------
      // 공통업무코드 조회 및 설정 처리
      // ---------------------------------------------------------------------------------
      this.$commonUtil.selListEACommCd('ZA_CELPH_KNB_CD').then(function () {
        // 휴대전화 앞자리 정보 세팅
        let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')

        lv_Vm.phoneItems.push({value: '0', text: '선택'})
        if (lv_CelphCD.length > 0) {
          lv_CelphCD.forEach(
            (item) => {
              let lv_Item = {}
              lv_Item.value = item.key
              lv_Item.text = item.key
              lv_Vm.phoneItems.push(lv_Item)
            }
          )
        }

        // 휴대폰번호 앞자리 초기값은 '010'
        lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value

        let userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo
        lv_Vm.custCardVO.cnsltNo = userInfo.cnsltNo

        lv_Vm.$nextTick(() => {
          lv_Vm.$refs['custNmRef'].focus()
        })
      })
    },
    mounted() {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPRC120M')
    },
    beforeDestroy () {
      this.$BottomManager.fn_SetBottomVisible(true)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        phoneItems: [],
        date: 0,
        now: Math.trunc((new Date()).getTime() / 1000),
        aprvNoErrCnt: 0, // 인증 실패 횟수
        isCountStart: false,
        authErrorMsg: '인증번호 오류입니다',
        interval: {},
        isAuthCompleted: false,
        isAuthError: false,
        telecomType: '',
        telecomTypes: [
          { value: 'SKT', text: 'SKT'},
          { value: 'KTF', text: 'KT' },
          { value: 'LGT', text: 'LG U+' },
          { value: 'SKTM',text: 'SKT(알뜰폰)' },
          { value: 'KTM', text: 'KT(알뜰폰)'  },
          { value: 'LGTM',text: 'LG U+(알뜰폰)'},
          { value: 'MCHK',text: '알뜰폰 확인하기'}
        ],
        prevTelecomType: '',
        custCardVO: {custNm: '', knbFrno: '', knbBkno: '', celno: '', celnoFrno: '',  celnoBkno: ''},
        agreeCheckbox: false,
        sCertVnoUrl: 'https://cert.vno.co.kr/app/agree/agree_main.jsp', // 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 URL
        agreeMsg:'', //모달 이용동의 내용
        authNumber: '',
        moblAthntCd: '', // 모바일인증코드
        moblAthntPswd: '', // 모바일인증비밀번호
        respUnqNo: '', // 응답고유번호
        reqUnqNo: '', // 요청고유번호
        authNumberDisabled: true,
        selfAthntUsScCd: '01', // 본인인증용 구분코드
        btnNm: '인증번호 전송',
        validateRuleRequire: [
          v => !!v || '필수입력항목입니다'
        ],
        validateRuleKnbFrno: [
          v => !!v || '필수입력항목입니다',
          v => v.length > 5 || '생년월일을 입력하세요.'
        ],
        validateRuleCelno: [
          v => !!v || '필수입력항목입니다',
          v => v.length > 7 || '휴대폰번호를 입력하세요.'
        ],
        isDev: (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2')
      }
    },
    filters: {
      twoDigits: function (value) {
        if (value.toString().length <= 1) {
          return '0' + value.toString()
        }
        return value.toString()
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      fn_TelecomType: { //통신사
        get: function () {
          return this.telecomType
        },
        set: function (data) {
          this.prevTelecomType = this.telecomType // 현재 선택되어 있는 값 복사
          this.telecomType = data
          this.fn_NextComp('celnoRef')
        }
      },
      modal(){
        return this.$refs.modalFrm
      },
      seconds () {
        let lv_Seconds = (this.remainDateValue - this.now) % 60
        if (lv_Seconds > 0) {
          return (this.remainDateValue - this.now) % 60
        } else {
          if (this.minutes === 0) {
            window.clearInterval(this.interval) // 타이머 중지
          }
          return 0
        }
      },
      minutes () {
        let lv_Minutes = Math.trunc((this.remainDateValue - this.now) / 60) % 60
        if (lv_Minutes > 0) {
          return Math.trunc((this.remainDateValue - this.now) / 60) % 60
        } else {
          return 0
        }
      },
      remainDateValue () {
        return Math.trunc(this.date / 1000)
      }
    },
    watch: {
      telecomType (value) {
        if (value === 'MCHK') { // 알뜰폰 확인하기 선택 시 팝업창 호출 (MSPCM010P 팝업)
          this.popup010 = this.$bottomModal.open(MSPCM010P, {
            properties: {
              pCustNm: this.custCardVO.custNm // 고객명 파라미터
            },
            listeners: {
              confirmPopup: (pData) => {
                this.$bottomModal.close(this.popup010)
                
                // 파라미터 세팅
                this.fn_ConfirmMSPCM010P(pData)
              }
            }
          })
        }
      },
      /*
      agreeCheckbox: function (val) {
        if (val) this.fn_AgreePopContent()
      },
      */
      'custCardVO.knbFrno' (data) {
        if (data.length === 6) {
          this.fn_NextComp ('knbBknoRef')
        }
      },
      'custCardVO.knbBkno' (data) {
        if (data.length === 1) {
          this.fn_NextComp ('telecomTypRef')
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_SheetClose
      * 설명       : 본인확인서비스 이용동의 안내 Close Fn
      *           - 약관동의로 Close / 강제로 Close 구분 목적
      * @param fn: BottomSheet Close Function
      * @param item: BottomSheet Event Name
      * @notice 강제로 / X버튼으로 닫을시 Event Name = 'swipe'
      *         - 약관 동의하게되면 item = 'S' 
      ******************************************************************************/
      fn_SheetClose(fn_CloseSheet, item) {
        console.log('Event Name: ', item)
        this.agreeCheckbox = item === 'swipe' ? false : true
        fn_CloseSheet()
      },
      /******************************************************************************
      * Function명 : fn_AgreeCnfrmPop2
      * 설명       : 본인확인서비스 이용동의 안내 
      *           - OPEN(컨펌팝업열기), Cancel(창닫기), Confirm(창닫기) 선택에 대한 화면 이동
      ******************************************************************************/
      fn_AgreeCnfrmPop2(param) {
        switch (param) {
          case 'Open':
            if(this.agreeCheckbox){
              this.agreeCheckbox = false
              this.$refs.agreeCnfrmPop2.open()
            }
            break
          case 'CloseBtn':
            this.$refs.agreeCnfrmPop2.close()
            this.fn_OnNegative()
            break
          case 'Confirm': // 약관 확인
            this.$refs.agreeCnfrmPop2.close('S')
            this.fn_AgreeConfirmYes() // 동의 나이스 링크 호출
            break
          case 'AllConfirm': // 전체 약관 확인
            this.$refs.agreeCnfrmPop2.close('S')
            this.fn_OnNegative()
            break
        }
      },      
      onSelect(value) {
        this.selectedItem = this.$refs.bottomSelect.getSelectedItem();
      },
      /******************************************************************************
      * Function명 : fn_ConfirmMSPCM010P
      * 설명       : 알뜰폰 안내 팝업창의 '확인', '취소' 버튼 클릭 시 호출
      ******************************************************************************/
      fn_ConfirmMSPCM010P (param) {
        if (param !== null && param !== '') {
          this.telecomType = param.val
        } else { // 취소버튼 클릭 시
          this.telecomType = this.prevTelecomType
        }
      },
      /******************************************************************************
      * Function명 : fn_AgreePopContent
      * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출
      ******************************************************************************/
      fn_AgreePopContent () {
        let lv_Url = this.sCertVnoUrl
        if (this.agreeCheckbox) {
          if (this.$commonUtil.isMobile()) { // 모바일에서 작동
            window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
          } else { // PC에서 작동
            window.open(lv_Url, '_blank')
          }
        }        
      },
      /******************************************************************************
      * Function명 : fn_AgreeConfirmYes
      * 설명       : 본인확인 서비스 이용 안내 confirm팝업창의 '동의' / '동의안함' 버튼 선택 시
      ******************************************************************************/
      fn_AgreeConfirmYes () {
          this.agreeCheckbox = true
          this.fn_AgreePopContent()
      },
      /******************************************************************************
       * Function명: fn_OnNegative
       * 설명: Confirm창 취소 버튼 클릭 함수
       ******************************************************************************/
      fn_OnNegative () {
        // '취소' 선택시 vuex 에 confirm 창을 닫았다고 저장한다.
        this.getStore('spStore').dispatch('SMS_CONFIRM', 'CLOSE')
      },
      /******************************************************************************
      * Function명 : fn_Validation
      * 설명       : 인증전송 버튼 클릭 시 유효성 체크
      ******************************************************************************/
      fn_Validation () {
        // 동의 체크 여부 확인
        let lv_RetVal = true
        if (this.agreeCheckbox === false) {
          // 본인확인 서비스 이용동의 모달창 띄움.
          this.modal.open()
          lv_RetVal = false
          return lv_RetVal
        }
        return lv_RetVal
      },
      /******************************************************************************
      * Function명 : fn_Validation
      * 설명       : 인증번호 버튼 클릭 시 유효성 체크
      ******************************************************************************/
      fn_ValidationCheck () {
        var lv_AgreeMsg = true
        
        if (this.$bizUtil.isEmpty(this.telecomType)) {
          this.getStore('confirm').dispatch('ADD', '통신사를 선택하세요')
          lv_AgreeMsg = false
          return lv_AgreeMsg
        }

        if (this.custCardVO.celnoFrno === '0') { // 핸드폰앞자리가 선택으로 되어 있는 경우
          this.getStore('confirm').dispatch('ADD', '핸드폰 앞자리를 선택하세요')
          lv_AgreeMsg = false
          return lv_AgreeMsg
        }
        
        return lv_AgreeMsg
      },
      /******************************************************************************
      * Function명 : fn_BtnP1OnClick
      * 설명       : 핸드폰 인증번호 요청
      ******************************************************************************/
      fn_BtnP1OnClick () {
        let agreeCheck = this.fn_Validation()
        if (!agreeCheck) {
          return
        }

        // let lv_Msg = this.fn_ValidationCheck()
        let ret = this.$refs.vWatcher.validate()

        if (ret === true) {
          let lv_Msg = this.fn_ValidationCheck()
          if (lv_Msg) {
            let lv_Vm = this
            lv_Vm.isCountStart = false // 타이머 초기화
            lv_Vm.isAuthError = false // 인증실패 초기화
            lv_Vm.isAuthCompleted = false // 인증여부 초기화
            lv_Vm.authNumber = '' // 인증번호 초기화
            let tmpKnb = lv_Vm.custCardVO.knbFrno + lv_Vm.custCardVO.knbBkno + '000000'

            if (lv_Vm.custCardVO.celnoFrno === '0') { // 핸드폰앞자리가 선택으로 되어 있는 경우
              lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
            }

            lv_Vm.custCardVO.celno = lv_Vm.custCardVO.celnoFrno + lv_Vm.custCardVO.celnoBkno
             
            let pParams = {
              custNm: lv_Vm.$bizUtil.isEmpty(lv_Vm.custCardVO.custNm) ? '' : lv_Vm.custCardVO.custNm, // 고객명
              selfAthntUsScCd: lv_Vm.selfAthntUsScCd, // 본인인증용도구분코드
              cmmuScCd: lv_Vm.telecomType === 'KTF' ? 'KT' : lv_Vm.telecomType, // 통신사
              // cmmuScCd: lv_Vm.telecomType.key, // 통신사
              // celno: lv_Vm.mobileNumber, // 핸드폰 번호
              celno: lv_Vm.custCardVO.celno.replace(/\-/g, ''),
              knb: 'CM',
              cnsltNo: lv_Vm.custCardVO.cnsltNo, // 컨설턴트 사번
              rrn: tmpKnb
            }

            let trnstId = 'txTSSRC10P1'
            this.post(lv_Vm, pParams, trnstId, 'S')
              .then(function (response) {
                if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {
                  let lv_TmpResult = response.body
                  if (lv_TmpResult) {
                    // lv_Vm.selfAthntRespUnQVal = lv_TmpResult.selfAthntRespUnQVal
                    // lv_Vm.nicePtptInstId = lv_TmpResult.nicePtptInstId

                    lv_Vm.moblAthntCd = lv_TmpResult.moblAthntCd // 모바일인증코드
                    lv_Vm.moblAthntPswd = lv_TmpResult.moblAthntPswd // 모바일인증비밀번호
                    lv_Vm.respUnqNo = lv_TmpResult.respUnqNo // 응답고유번호
                    lv_Vm.reqUnqNo = lv_TmpResult.reqUnqNo // 요청고유번호

                    // 타이머 세팅 및 시작
                    lv_Vm.isCountStart = true
                    lv_Vm.date = moment(new Date()).add(7, 'm').toDate() // 인증 유효 시간을 7분으로 timer 세팅
                    lv_Vm.interval = window.setInterval( () => {
                      lv_Vm.now = new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime() / 1000 | 0
                    }, 1000)

                    lv_Vm.authNumberDisabled = false // 인증번호 Text-Input 활성화
                    lv_Vm.btnNm = '인증번호 재전송' // 인증번호 재전송 버튼으로 변경
                    lv_Vm.aprvNoErrCnt = 0 // 실패 횟수는 다시 0으로 초기화
                  }
                } else { // 정상처리가 아닌경우
                  lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
                }
              })
              .catch(function (error) {
                window.vue.error(error)
                if (!_.isEmpty(error.message)) {
                  lv_Vm.getStore('confirm').dispatch('ADD', error.message)
                } else {
                  lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('rc')['ERCC001']) // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
                }
              })
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_PrcsMoblAthntCnf
      * 설명       : 핸드폰 인증번호 확인
      ******************************************************************************/
      fn_PrcsMoblAthntCnf () {
        let lv_Vm = this
        let lv_RetVal = true
        if (_.isEmpty(this.authNumber) || this.authNumber.length !== 6) {
          lv_Vm.getStore('confirm').dispatch('ADD', '인증번호 정보가 정확하지 않습니다.') // 인증번호 정보가 정확하지 않습니다.
          lv_RetVal = false
        }
        if (!lv_RetVal) {
          return
        }
        let tmpKnb = lv_Vm.custCardVO.knbFrno + lv_Vm.custCardVO.knbBkno + '000000'
        // let trnstId = 'txTSSCM12S0'
        lv_Vm.aprvNoErrCnt++ // 인증번호 전송 횟수 체크 (3회까지만 허용됨)
        let pParams = {
          custId: lv_Vm.$bizUtil.isEmpty(lv_Vm.custCardVO.custId) ? '' : lv_Vm.custCardVO.custId, // 고객ID
          custNm: lv_Vm.$bizUtil.isEmpty(lv_Vm.custCardVO.custNm) ? '' : lv_Vm.custCardVO.custNm, // 고객명
          selfAthntUsScCd: lv_Vm.selfAthntUsScCd, // 본인인증용도구분코드
          celno: lv_Vm.custCardVO.celno.replace(/\-/g, ''), // 핸드폰 번호
          knb: 'CM',
          rrn: tmpKnb,
          moblAthntCd: lv_Vm.moblAthntCd, // 모바일인증코드
          moblAthntPswd: lv_Vm.moblAthntPswd, // 모바일인증비밀번호
          reqUnqNo: lv_Vm.reqUnqNo, // 요청고유번호
          respUnqNo: lv_Vm.respUnqNo, // 응답고유번호
          athntNo: lv_Vm.authNumber, // 인증번호
          aprvNoErrCnt: lv_Vm.aprvNoErrCnt, // 인증번호 오류 횟수
          cnsltNo: this.custCardVO.cnsltNo, // 컨설턴트 사번
          chnlCustId: this.custCardVO.chnlCustId // 채널고객ID
        }

        let trnstId = 'txTSSRC10P2'
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {
              let lv_TmpResult = response.body
              if (lv_TmpResult) {
                window.clearInterval(lv_Vm.interval) // 타이머 종료
                lv_Vm.isCountStart = false
                lv_Vm.isAuthError = false
                lv_Vm.isAuthCompleted = true
                lv_Vm.authNumberDisabled = true
                // 고객등록페이지 이동
                lv_Vm.fn_AuthCompleted()
              }
            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
              lv_Vm.isAuthError = true
              lv_Vm.isAuthCompleted = false
              if (response.msgComm.msgCd === 'ECMU001') { // 인증번호 3회 오류 시
                window.clearInterval(lv_Vm.interval) // 타이머 종료
                lv_Vm.isCountStart = false
                lv_Vm.authNumberDisabled = true // 인증번호 Text-Input 비활성화
                lv_Vm.authNumber = '' // 인증번호 초기화
              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
            if (!_.isEmpty(error.message)) {
              lv_Vm.getStore('confirm').dispatch('ADD', error.message)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('rc')['ERCC001']) // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
            }
          })
      },
      /******************************************************************************
      * Function명 : fn_AuthCompleted
      * 설명       : 고객등록페이지 이동
      ******************************************************************************/
      fn_AuthCompleted () {
        let tmpCelno = this.custCardVO.celnoFrno + this.custCardVO.celnoBkno
        this.custCardVO.celno = tmpCelno.replace(/\-/g, ''), // 핸드폰 번호
        this.$router.push( { name: 'MSPRC150M', params: { custCardVO: this.custCardVO} } )
      },
      fn_NextComp (param) {
        if (param === 'knbFrnoRef') { // 이름에서 focus out 되었을 때
          if (this.$bizUtil.isEmpty(this.custCardVO.custNm)) {
            return
          }
          this.$refs[param].focus()
        } else if (param === 'knbBknoRef') { // 주민번호 앞자리에서 focus out 되었을 때
          if (this.custCardVO.knbFrno.length < 6) {
            return
          }
          this.$refs[param].focus()
        } else if (param === 'telecomTypRef') { // 주민번호 뒷자리에서 focus out 되었을 때
          if (this.custCardVO.knbBkno.length < 1) {
            return
          }
          this.$refs['knbBknoRef'].blur()
          this.$refs[param].show()
        } else if (param === 'celnoRef') { // 통신사 선택 후
          this.$refs[param].focus()
        }

        // this.$refs[param].focus()
      }
    }
  }
</script>
<style scoped>
</style>